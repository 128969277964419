<template>
    <div class="trade-struct">
        <el-card>
            <div slot="header" class="clearfix">
                <span>成交结构</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：展示每个品种的成交额占比。</p>
                <p>
                    如何使用：统计账户操作过程中风险暴露主要集中的品种，或者反映投资者的品种偏好，建议尽量均匀分配自己最擅长品种的仓位，如此可以借助品种之间的差异性平滑一部分组合风险，制造更完美的资金曲线，如果您对所有品种都很擅长，那就最好不过了。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {varietyFormatter} from '../../utils/tool'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "TradeStruct",
    data () {
      return {
        starLevel: 3,
        options: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            },
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: false,
          // colors:[
          //     '#EB3E41', '#FC8F22', '#229AFC', '#4FCB77', '#3922FC', '#9C22FC',
          //     '#DA6AAD', '#FFB981', '#5CB0EF', '#DFD211', '#5D8EDC', '#C4B2E4',
          //     '#FFA693', '#DFD211', '#26CACE', '#CBEC77', '#9196B3', '#9582D2',
          //     '#FFA5B9', '#FFDB87', '#88FFEC', '#8DEC77', '#88A9FF', '#FFA5E7',
          // ],
          colors: [
            '#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80',
            '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa',
            '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050',
            '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'
          ],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
      };
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('tradeStruct', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let f1s = []
          Array.isArray(resData) && resData.map(item => {
            f1s.push([varietyFormatter(item.code),item.f1])
          })
          this.options.series = [{
            name: '成交结构',
            data: f1s
          }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      }
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .trade-struct {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
